import React from 'react';
import useDeviceType from '../../hooks/useDeviceType';
import AnkerVideoContentMobile from './anker-video-content-mobile';
import AnkerVideoContent from './anker-video-content';

export default function AnkerVideo({
  url, poster = {}, text, subText, location, marginTop,
}) {
  const { isMobile } = useDeviceType();

  const videoUrl = isMobile ? url.mobile : url.pc;
  return (
    <>
      {
        isMobile
          ? <AnkerVideoContentMobile
              url={videoUrl}
              text={text}
              subText={subText} />
          : <AnkerVideoContent
              url={videoUrl}
              poster={poster}
              text={text}
              subText={subText}
              location={location}
              marginTop={marginTop}
            />
      }
    </>
  );
}
