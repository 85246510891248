/**
 * innovative-products 核心内容
 */
import React from 'react';
import { ReactSVG } from 'react-svg';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';
import useDeviceType from '../../hooks/useDeviceType';
import AnkerLogo from '../../images/anker-logo.svg';
import EufyLogo from '../../images/eufy-logo.svg';
import SoundcoreLogo from '../../images/soundcore-logo.svg';
import SoundcoreLogoBlack from '../../images/soundcore-logo-black.svg';
import AnkerMobileLogo from '../../images/anker-mobile-logo.svg';
import EufyMobileLogo from '../../images/eufy-mobile-logo.svg';
import SoundcoreMobileLogo from '../../images/soundcore-mobile-logo.svg';
import SoundcoreMobileLogoBlack from '../../images/soundcore-mobile-logo-black.svg';

export default function InnovativeProductsContent({ images, content, comment }) {
  // 面板数量
  const panelCount = images.expand?.length || content.length || 0;
  const { isMobile } = useDeviceType();

  const wrapperClsNames = `w-full ${isMobile ? 'h-[572px]' : 'h-[682px]'}`;
  const inactivePanelClsNames = isMobile ? 'flex-grow-[0.5]' : 'flex-grow-[0.9]';

  return (
    <>
      <Accordion direction="vertical" wrapperClsNames={wrapperClsNames} panelCount={panelCount} inactivePanelClsNames={inactivePanelClsNames}>
        <Content images={images} content={content} />
      </Accordion>
      <div className="mt-3 xl:mt-4 text-xs xl:text-sm text-[#86868C] font-light">{comment}</div>
    </>
  );
}

function Content({
  activeIndex, currentIndex, images, content,
}) {
  const active = activeIndex === currentIndex;
  // 当前展开状态，展示 expand 图片，否则展示 collapse 图片
  const imageStatusKey = active ? 'expand' : 'collapse';
  // 因图片原因，第三个面板的图片需要字体颜色为黑色
  const isThirdPanel = currentIndex === 2;
  const { isMobile } = useDeviceType();
  const logos = isMobile
    ? [AnkerMobileLogo, EufyMobileLogo, active ? SoundcoreMobileLogoBlack : SoundcoreMobileLogo]
    : [AnkerLogo, EufyLogo, active ? SoundcoreLogoBlack : SoundcoreLogo];

  return (
    <IntlContextConsumer>
      { ({ language }) => {
        const isEn = language === 'en';

        return (
          <div className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
            {!!images[imageStatusKey]?.length && <img className="absolute top-0 left-0 w-full h-full object-cover" src={images[imageStatusKey][currentIndex]} alt="img" />}
            <div className={`absolute top-0 left-0 w-full h-full bg-none  ${active ? 'items-start flex-col pt-6 pb-4' : 'items-center'} flex justify-between px-4 lg:px-8 lg:py-10 ${isThirdPanel ? 'text-[#1D1D1F]' : ''}`}>
              <ReactSVG src={logos[currentIndex]} />
              <div className={`w-full max-w-[492px] ${active ? 'block' : 'hidden'}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
              { !active && <span className={'iconfont iconarrow_down !text-xl font-bold text-white'}></span>}
            </div>
          </div>
        );
      }
      }
    </IntlContextConsumer>
  );
}
