/**
 * 主页内容区域第四部分 - 为什么我们是Makers创造者的乐园？
 */
import React from 'react';
import useDeviceType from '../../hooks/useDeviceType';
import MakersParadiseContentMobile from './makers-paradise-content-mobile';
import MakersParadiseContent from './makers-paradise-content';
import './makers-paradise.scss';

export default function MakersParadise({
  title, subTitle, image, content, onOpacityStartChange,
}) {
  const { isMobile } = useDeviceType();
  const images = isMobile ? image.mobile : image.pc;

  return (
  // <ScrollDrivenAnimation>
  <>
    {isMobile
      ? (
        <MakersParadiseContentMobile
          title={title}
          subTitle={subTitle}
          images={images}
          content={content}
          onOpacityStartChange={onOpacityStartChange}
        />
      ) : (
        <MakersParadiseContent
          title={title}
          subTitle={subTitle}
          images={images}
          content={content}
          onOpacityStartChange={onOpacityStartChange}
        />
      )}
  </>
  // </ScrollDrivenAnimation>
  );
}
