/**
 * makers-values 主体内容 - PC端
 */
import React, { useEffect, useState, useRef } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';
import './makers-values.scss';

export default function MakersValuesContent({ images = [], content = [] }) {
  // 面板数量
  const panelCount = images.expand?.length || content.length || 0;

  return (
    <Accordion
      panelCount={panelCount}
      content={content}
      wrapperClsNames="h-[632px]"
      inactivePanelClsNames="flex-grow-[1.5]"
    >
      <Content images={images} content={content} />
    </Accordion>
  );
}

function Content({
  activeIndex, currentIndex, images, content,
}) {
  const active = activeIndex === currentIndex;
  const imageStatusKey = active ? 'expand' : 'collapse';

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  const showLearnMore = !isExpanded && isOverflowing;

  useEffect(() => {
    if (contentRef.current) {
      const checkOverflow = () => {
        setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
      };

      // 使用 ResizeObserver 监听元素尺寸变化
      const resizeObserver = new ResizeObserver(() => {
        checkOverflow();
      });

      resizeObserver.observe(contentRef.current);
      // 初始检查
      checkOverflow();

      // 清除事件监听器
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [activeIndex]);

  const handleExpand = () => {
    setIsExpanded(true);
  };

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';
          const learnMoreText = isEn ? 'Learn More' : '查看更多';
          return (
          <div key={currentIndex} className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
            {!!images[imageStatusKey]?.length && <img className={'absolute top-0 left-0 w-full h-full object-cover'} src={images[imageStatusKey][currentIndex]} alt="img" />}
            <div className={`absolute top-0 left-0 w-full h-full ${active ? `${isEn ? 'hover:bg-black hover:bg-cover hover:bg-opacity-30' : ''} items-start flex-col` : ''} bg-none flex justify-between px-4 py-6 lg:px-8 lg:py-10`}>
              <div className="w-full">
                <div dangerouslySetInnerHTML={{ __html: content[currentIndex].title }} />
                {content[currentIndex].subTitle && <div className={`${active ? 'animate-fadeIn' : 'opacity-0 hidden'}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].subTitle }} />}
              </div>
              <div className={`w-full ${active ? 'animate-fadeIn' : 'opacity-0 hidden'}`}>
                <div ref={contentRef} className={`w-full overflow-y-hidden ${showLearnMore ? 'max-h-[198px]' : 'max-h-[228px]'} ${isExpanded ? 'hover:overflow-y-auto custom-scrollbar hover:w-[calc(100%+4px)]' : ''}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
                {showLearnMore && <div className="text-[14px]  learn-more-underline cursor-pointer mt-2" onClick={handleExpand}>{learnMoreText}</div>}
              </div>
            </div>
          </div>);
        }
      }
    </IntlContextConsumer>
  );
}
