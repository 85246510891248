/**
 * anker-video 移动端
 */
import React from 'react';
import useLanguage from '../../hooks/useLanguage';

export default function AnkerVideoContentMobile({
  url, text, subText,
}) {
  const { isEn } = useLanguage();

  return (
    <div className="anker-video w-full relative mt-10">
      <div className="w-full after:content-[''] after:absolute after:inset-0 after:bg-black after:opacity-20 after:pointer-events-none" >
        <img className="w-full object-cover" src={url} alt="anker-video" />
      </div>
      <div className="video-text-wrapper w-full absolute top-1/2 -translate-y-1/2 left-0 z-[1] flex flex-col items-center text-center text-[#F5F5F7] px-6">
        <div className={`video-title mb-3 md:mb-6 ${isEn ? 'text-xl' : 'text-2xl'} md:text-5xl ${isEn ? 'font-title-en' : 'font-title-zh'}`} dangerouslySetInnerHTML={{ __html: text }} />
        <div className="video-sub-title text-sm md:text-xl font-content" dangerouslySetInnerHTML={{ __html: subText }} />
      </div>
    </div>
  );
}
