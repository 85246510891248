/**
 * 插槽组件
 */
import React, { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const Slot = forwardRef(({ name, children, scope }, ref) =>
  React.Children.map(children, (child) => {
    if (child && child.props?.slot === name) {
      return React.cloneElement(child, { ...scope, ...child.props, ref });
    }
    return null;
  }));

export default Slot;
