import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import * as styles from './anker-story.module.scss';
import useDeviceType from '../../hooks/useDeviceType';

const Introduce = (props) => {
  const { isMobile } = useDeviceType();

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(true);
  };

  return <div className="w-screen mx-auto box-border px-6 md:px-0 pb-10">
    <motion.div
      initial={{ opacity: 0, translateY: 100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={`max-w-[1064px] flex flex-col justify-center overflow-hidden text-base md:text-xl font-normal md:leading-9 text-[#1D1D1F] mx-auto  ${styles.introduceDesc}`}>
        {isMobile ? props.mobiledesc.map((item, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
        )) : props.desc.map((item, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </div>
    </motion.div>
  </div>;
};

export default Introduce;
