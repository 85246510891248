/**
 * 超越用户最本质的需求，更多产品创新等待Makers 探索实现
 */
import React from 'react';
import SectionTitle from './v2/section-title';
import InnovativeProductsContent from './innovative-products-content';
import ScrollDrivenAnimation from './scroll-driven-animation';
import './innovative-products.scss';
import useDeviceType from '../../hooks/useDeviceType';

export default function InnovativeProducts({
  title, subTitle, image, content, comment,
}) {
  const { isMobile } = useDeviceType();
  const images = isMobile ? image.mobile : image.pc;

  return (
  // <ScrollDrivenAnimation>
      <div className="innovativeProducts max-w-[1200px] m-auto px-6 xl:px-0">
        <SectionTitle title={title} subTitle={subTitle} colors={{ titleColor: 'black' }} />
        <InnovativeProductsContent images={images} content={content} comment={comment} />
      </div>
  // </ScrollDrivenAnimation>
  );
}
