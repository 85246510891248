/**
 * 主页手风琴折叠面板
 */
import React, { useState } from 'react';
import { range, debounce } from 'lodash';
import AccordionPanel from './accordion-panel';
import Slot from '../common/slot';

/**
 *
 * @param {*} direction 方向，horizontal 水平 或 vertical 垂直
 * @param {*} panelCount 折叠面板数量
 * @param {*} wrapperClsNames 折叠面板容器类名集合，字符串模式
 * @param {*} onActiveIndexChange 展开面板变更处理函数，参数 index
 * @param {*} children 自定义折叠面板内容
 * @param {*} activePanelClsNames 展开的折叠面板类名集合，字符串模式
 * @param {*} inactivePanelClsNames 收起的折叠面板类名集合，字符串模式
 * @returns
 */
export default function Accordion({
  direction = 'horizontal',
  panelCount = 0,
  wrapperClsNames = '',
  children,
  activePanelClsNames,
  inactivePanelClsNames,
  onActiveIndexChange,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  // 是否水平方向
  const isVertical = direction === 'vertical';
  const panelSizeClsName = isVertical ? 'w-full' : 'h-full';
  // 容器类名集合
  const resolvedWrapperClassNames = `flex align-center justify-start ${wrapperClsNames} ${isVertical ? 'flex-col' : ''}`;
  // 展开面板类名集合
  const resolvedActiveClsNames = activePanelClsNames || `flex-grow-${panelCount}`;
  // 折叠面板类名集合
  const resolvedInactiveClsNames = inactivePanelClsNames || 'flex-grow';

  // 展开索引变更处理事件
  const handleActiveIndexChange = debounce((index) => {
    setActiveIndex(index);
    onActiveIndexChange?.(index);
  }, 100);

  return (
    <div className={resolvedWrapperClassNames}>
      {
        range(panelCount).map((i) => (
          <AccordionPanel active={activeIndex === i} key={i} classNames={`relative overflow-hidden text-white transition-all duration-500 ${panelSizeClsName}`} activePanelClsNames={resolvedActiveClsNames} inactivePanelClsNames={resolvedInactiveClsNames} onActiveChange={() => handleActiveIndexChange(i)}>
            <Slot scope={{ activeIndex, currentIndex: i }}>{ children }</Slot>
          </AccordionPanel>
        ))
      }
    </div>
  );
}
