import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
// import ScrollDrivenAnimation from "./scroll-driven-animation";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import * as styles from './anker-story.module.scss';
import SectionTitle from './v2/section-title';

const NewsItem = (props) => {
  const {
    news, current, handelPrev, handelNext, pathname,
  } = props;
  const [isEnWebsite, setIsEnWebsite] = useState(false);
  const textContainerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  // 单个 slide 的 ref
  const slideRef = useRef(null);
  const leftPaginationBtnRef = useRef(null);
  const rightPaginationBtnRef = useRef(null);
  const [leftPaginationXlCls, setLeftPaginationXlCls] = useState('');
  const [rightPaginationXlCls, setRightPaginationXlCls] = useState('');
  // 监听 slide，根据 slide 的宽度，动态设置切换页面按钮的 position，仅限 xl（1280 - 1536） 分辨率下
  useEffect(() => {
    if (!slideRef.current) return;
    const windowWidth = window.innerWidth;
    // 仅处理 1280 - 1536 之间的分辨率
    if (windowWidth <= 1280 && windowWidth > 1536) return;

    const ro = new ResizeObserver(([entry]) => {
      const { width } = entry.contentRect;
      const { width: btnWidth } = leftPaginationBtnRef.current.getBoundingClientRect();
      // slide 到视口两侧的距离
      const slideMargin = (windowWidth - width) / 2;
      /**
       * 定位规则：
       * 1. 若 slideMargin < btnWidth，则按钮定位在 slide 上，left 8px，right 8px
       * 2. 若 100px > slideMargin > btnWidth，则按钮定位在 slide 两侧，动态计算 left 和 right，
       *    因为动态计算的 tailwindcss 类不生效，因此使用 style 设置
       * 3. 若 slideMargin > 100px，则按钮定位在 slide 两侧，left 100px，right 100px
       */
      if (slideMargin < btnWidth) {
        setLeftPaginationXlCls('xl:left-2');
        setRightPaginationXlCls('xl:right-2');
      } else if (slideMargin < 100) {
        leftPaginationBtnRef.current.style.left = `-${slideMargin - 16}px`;
        rightPaginationBtnRef.current.style.right = `-${slideMargin - 16}px`;
      } else {
        setLeftPaginationXlCls('xl:-left-[100px]');
        setRightPaginationXlCls('xl:-right-[100px]');
      }
    });

    ro.observe(slideRef.current);

    return () => {
      ro.disconnect();
    };
  }, [slideRef]);

  useEffect(() => {
    if (!current) {
      const checkOverflow = () => {
        setIsOverflowing(
          textContainerRef.current.scrollHeight > textContainerRef.current.clientHeight,
        );
      };

      // 使用 ResizeObserver 监听元素尺寸变化
      const resizeObserver = new ResizeObserver(() => {
        checkOverflow();
      });

      resizeObserver.observe(textContainerRef.current);
      // 初始检查
      checkOverflow();

      // 清除事件监听器
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [current]);

  useEffect(() => {
    if (pathname.indexOf('/en') > -1) {
      setIsEnWebsite(true);
    } else {
      setIsEnWebsite(false);
    }
  }, [pathname]);
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener('resize', setMobile);
    return () => window.removeEventListener('resize', setMobile);
  }, [setMobile]);

  return (
    <>
      <a ref={leftPaginationBtnRef} id="swiper-prev" className={`text-3xl hidden md:block bg-[#353537] hover:bg-[#555555] absolute z-10 top-1/2 left
                md:left-[2%] 2xl:-left-[9%] py-1 lg:py-1.5 xl:py-2 px-2 lg:px-3 xl:px-4
                rounded-full cursor-pointer active:scale-90 transition-transform ${leftPaginationXlCls}`}
        style={{ display: current || isMobile ? 'none' : 'block' }}
        onClick={() => handelPrev()}
      >
        <svg
          width="27"
          height="20"
          viewBox="0 0 27 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="md:scale-50 lg:scale-75 xl:scale-100"
        >
          <rect
            x="26.8945"
            y="11.9248"
            width="24"
            height="4"
            transform="rotate(-180 26.8945 11.9248)"
            fill="#fff"
          />
          <rect
            x="0.166016"
            y="10.0255"
            width="14"
            height="4"
            transform="rotate(-45 0.166016 10.0255)"
            fill="#fff"
          />
          <rect
            x="2.99414"
            y="7.19687"
            width="14"
            height="4"
            transform="rotate(45 2.99414 7.19687)"
            fill="#fff"
          />
        </svg>
      </a>
      <a
        ref={rightPaginationBtnRef}
        id="swiper-next"
        className={`text-3xl hidden md:block bg-[#353537] hover:bg-[#555555] absolute z-10 top-1/2
                md:right-[2%] 2xl:-right-[9%] py-1 lg:py-1.5 xl:py-2 px-2 lg:px-3 xl:px-4
                rounded-full cursor-pointer active:scale-90 transition-transform ${rightPaginationXlCls}`}
        style={{ display: current || isMobile ? 'none' : 'block' }}
        onClick={() => handelNext()}
      >
        <svg
          width="27"
          height="20"
          viewBox="0 0 27 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="md:scale-50 lg:scale-75 xl:scale-100"
        >
          <rect
            x="0.164062"
            y="8.12599"
            width="24"
            height="4"
            fill="#fff"
          />
          <rect
            x="26.8926"
            y="10.0253"
            width="14"
            height="4"
            transform="rotate(135 26.8926 10.0253)"
            fill="#fff"
          />
          <rect
            x="24.0645"
            y="12.8539"
            width="14"
            height="4"
            transform="rotate(-135 24.0645 12.8539)"
            fill="#fff"
          />
        </svg>
      </a>
      <div ref={slideRef} className={`flex flex-wrap transition-transform duration-500 group overflow-hidden ${current ? !isMobile ? 'scale-[88%]' : 'scale-[89.4%]' : ''} ${!isMobile ? 'bg-[#1D1D1F]' : ''}`}>
        <div className={`${isMobile ? 'w-[100%]' : 'w-[48%]'} ${current ? '-mt-14' : ''} h-auto flex-shrink-0 overflow-hidden`}>
          <img src={isMobile ? news.mobileimage : news.image} className={'w-full h-full object-cover object-center hover:scale-110 transition-transform duration-500'} />
        </div>
        <div className={`${isMobile ? 'w-[100%]' : 'w-[52%] h-full'} h-auto md:h-[412px] lg:h-[540px] xl:h-[576px] 2xl:h-[576px]`}>
          <div className={`mx-[0] mt-[24px] mb-[16px] md:m-[16px] lg:m-[24px] xl:m-[32px] 2xl:m-[40px] ${!isMobile ? 'overflow-hidden items-start flex-col flex justify-between md:h-[calc(100%-32px)] ld:h-[calc(100%-48px)] xl:h-[calc(100%-64px)] 2xl:h-[calc(100%-80px)]' : ''}`}>
            {(!isMobile || (isMobile && !current)) && <>
              <div>
                <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} border-[#B6B6BA] bg-[#DDDDDD] text-[#1D1D1F] px-[14px] py-[4px] leading-[16px] md:leading-[26px] text-[12px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[14px] rounded-full inline-block`}>{news.title}</div>
                <p className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] mt-[12px] ] leading-[20px] md:leading-[32px] text-[16px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px]`}>{news.subtitle}</p>
              </div>
              <div ref={textContainerRef} className={`w-full ${isEnWebsite ? 'font-[300] leading-[16px] md:leading-[1.5em]' : 'font-[300] leading-[1.5em]'}  text-[#B6B6BA]  text-[12px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] ${!isMobile ? `md:max-h-[calc(100%-102px)] lg:max-h-[calc(100%-102px)] xl:max-h-[calc(100%-112px)] 2xl:max-h-[calc(100%-112px)] overflow-y-auto custom-scrollbar pr-[4px] ${isOverflowing ? 'hover:pr-0' : ''}` : ''} ${styles.desc} mt-[24px] md:mt-[24px] lg:mt-[24px] xl:mt-[34px] 2xl:mt-[34px]`} dangerouslySetInnerHTML={{ __html: news.desc }} />
            </>}
          </div>

        </div>
      </div>
    </>
  );
};

const AnkerStory = (props) => {
  const [realIndex, setRealIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener('resize', setMobile);
    return () => window.removeEventListener('resize', setMobile);
  }, [setMobile]);
  let renderList = props.list;
  // 如果props.list的数量大于0小于或等于3，就复制一份（Swiper小于或等于3轮播会异常）
  if (props.list.length > 0 && props.list.length <= 3) {
    renderList = [...props.list, ...props.list];
  }
  const swiperRef = useRef(null);
  const swiperPrev = () => {
    swiperRef.current?.slidePrev();
  };
  const swiperNext = () => {
    swiperRef.current?.slideNext();
  };
  return (
    <div className="ankerStory">
      {/* <ScrollDrivenAnimation> */}
      <div className="bg-black relative">
        {/* <section className="overflow-auto bg-black relative z-10"> */}
        <div style={{ opacity: 1, transform: 'scale(1)' }}>
          {/* <div className="pt-4 md:pt-[2%]"> */}
          <div className="px-6 w-full">
            <div className="mx-auto box-border xl:max-w-screen-xl ">
              <SectionTitle title={props.title} />
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            // navigation={{ nextEl: "#swiper-next", prevEl: "#swiper-prev" }}
            slidesPerView="auto"
            // slidesPerView={1.2}
            spaceBetween={-10}
            centeredSlides
            loop
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            // breakpoints={{
            //   768: { spaceBetween: 10, slidesPerView: 1.3 },
            //   1280: { spaceBetween: 100, slidesPerView: 1.4 }
            // }}
            breakpoints={{
              768: { spaceBetween: 10 },
              1280: { spaceBetween: 100 },
            }}
            onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {renderList.map((item, index) => (
              <SwiperSlide key={index} className={`rounded ${isMobile ? styles.mobileSwiperSlide : styles.swiperSlide}`}>
                <NewsItem news={item} current={realIndex !== index} handelPrev={swiperPrev} handelNext={swiperNext} pathname={props.location.pathname} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* </div> */}
        </div>
        {/* </section> */}
      </div>
      {/* </ScrollDrivenAnimation> */}
    </div>
  );
};

export default AnkerStory;
