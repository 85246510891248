/**
 * anker 视频
 */
import React, { useRef, useEffect, useState } from 'react';
import useLanguage from '../../hooks/useLanguage';
import useDeviceType from '../../hooks/useDeviceType';

export default function AnkerVideoContent({
  url, poster = {}, text, subText, location, marginTop,
}) {
  const { isEn } = useLanguage(location);
  const { isMobile } = useDeviceType();
  const videoRef = useRef(null);
  const textRef = useRef(null);
  const [textStyle, setTextStyle] = useState({ transform: 'translateY(100%)', opacity: 0 });

  const posterUrl = isMobile ? poster.mobile : poster.pc;

  useEffect(() => {
    let observer;
    let isFixed = false;
    let fixedScrollY = 0;
    let textPosition = 'bottom';
    const handleScroll = () => {
      if (!videoRef.current || !textRef.current) return;

      const videoRect = videoRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      // 计算文字的 transform 和 opacity
      const videoHeight = videoRef.current.offsetHeight;
      const textHeight = textRef.current.offsetHeight;
      let scrollY = window.scrollY || window.pageYOffset || 0;

      // 判断 video 是否需要固定
      const fixedCondition = !isFixed && ((videoRect.top <= 0 && textPosition === 'bottom') || (videoRect.top >= 0 && textPosition === 'top'));
      if (fixedCondition) {
        isFixed = true;
        fixedScrollY = textPosition === 'top' ? scrollY : scrollY;
      }

      if (!isFixed) return;

      // 文字的三个位置节点
      // 文本内容高度 50vh（padding） * 2 + textHeight
      const topPoint = fixedScrollY;
      const centerPoint = fixedScrollY + windowHeight / 2;
      const bottomPoint = fixedScrollY + windowHeight * 1.5;

      // 总位移距离
      const baseTranslateY = 0;
      if (textPosition === 'bottom') {
        const distance = windowHeight / 2;
        // 向下滚动，translateY 为负数
        const translateYPx = topPoint - scrollY + baseTranslateY;
        // 1. 滚动条滚动到视窗中上部，文字向上移动，逐渐淡入
        if (scrollY <= centerPoint && scrollY >= topPoint) {
          const progress = (scrollY - topPoint) / distance;
          const opacity = progress;
          setTextStyle({ transform: `translateY(${translateYPx}px)`, opacity });
        } else if (scrollY <= bottomPoint && scrollY > centerPoint) {
        // 2 . 滚动条滚动到视窗中下部，文字向上移动，逐渐淡出
          const progress = (scrollY - centerPoint) / distance;
          const opacity = 1 - progress;
          setTextStyle({ transform: `translateY(${translateYPx}px)`, opacity });
        } else if (scrollY > bottomPoint) {
          setTextStyle({ transform: `translateY(-${videoHeight + textHeight}px)`, opacity: 0 });
          if (isFixed) {
            isFixed = false;
            textPosition = 'top';
          }
        } else {
          setTextStyle({ transform: `translateY(${baseTranslateY}px)`, opacity: 0 });
          if (isFixed) {
            isFixed = false;
            textPosition = 'bottom';
          }
        }
      } else {
        // 1. 滚动条滚动到视窗中上部，文字向下移动，逐渐淡如
        // 此时 scrollY 对齐 video 底部，逐渐减小
        scrollY += windowHeight;
        const distance = bottomPoint - centerPoint;
        const translateYPx = topPoint - scrollY + baseTranslateY;
        if (scrollY <= centerPoint && scrollY >= topPoint) {
          const progress = (scrollY - topPoint) / distance;
          const opacity = progress;
          setTextStyle({ transform: `translateY(${translateYPx}px)`, opacity });
        } else if (scrollY <= bottomPoint && scrollY > centerPoint) {
        // 2 . 滚动条滚动到视窗中下部，文字向下移动，逐渐淡出
          const progress = (scrollY - centerPoint) / distance;
          const opacity = 1 - progress;
          setTextStyle({ transform: `translateY(${translateYPx}px)`, opacity });
        } else if (scrollY > bottomPoint) {
          setTextStyle({ transform: `translateY(-${videoHeight + textHeight}px)`, opacity: 0 });
          if (isFixed) {
            isFixed = false;
            textPosition = 'top';
          }
        } else {
          setTextStyle({ transform: `translateY(${baseTranslateY}px)`, opacity: 0 });
          if (isFixed) {
            isFixed = false;
            textPosition = 'bottom';
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (observer) observer.disconnect();
    };
  }, []);

  const [containerMarginTop, setContainerMarginTop] = useState(0);
  useEffect(() => {
    setContainerMarginTop(`-${marginTop}px`);
  }, [marginTop]);

  return (
    <>
      <div className={'anker-video relative w-full'} style={{ marginTop: containerMarginTop }}>
        <div className="sticky top-0 w-full h-screen after:content-[''] after:absolute after:inset-0 after:bg-black after:opacity-20 after:pointer-events-none" >
          <video
            ref={videoRef}
            src={url}
            poster={posterUrl}
            className="w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsinline=""
            webkit-playsinline=""
            x5-playsinline=""
            width={1920}
            height={1080}
            type="video/mp4" />
        </div>
        <div className="video-text-wrapper relative w-full py-[50vh]">
          <div ref={textRef} className="w-full px-6 flex flex-col items-center text-center text-[#F5F5F7] absolute transform" style={textStyle}>
            <div className={`max-w-[1200px] video-title mb-4 md:mb-6 text-2xl ${isEn ? 'font-title-en md:text-[2.5rem] md:leading-[3rem]' : 'font-title-zh md:text-5xl md:leading-[3.5rem]'}`} dangerouslySetInnerHTML={{ __html: text }} />
            <div className="max-w-[1200px] video-sub-title text-sm md:text-xl font-content leading-6 md:leading-7" dangerouslySetInnerHTML={{ __html: subText }} />
          </div>
        </div>
      </div>
    </>
  );
}
