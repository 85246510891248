/**
 * 包含 maker-paradise 和 anker-video 的容器
 */
import React, { useState } from 'react';
import MakersParadise from './makers-paradise';
import AnkerVideo from './anker-video';

export default function ParadiseVideoWrap({ makersParadise, video, location }) {
  const [videoMarginTop, setVideoMarginTop] = useState(0);

  return (
    <>
      <MakersParadise
        {...makersParadise}
        location={location}
        onOpacityStartChange={(bottom) => setVideoMarginTop(bottom)}
      />
      <AnkerVideo {...video} location={location} marginTop={videoMarginTop} />
    </>
  );
}
