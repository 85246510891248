/**
 * makers-award 主体内容
 */
import React, { useState } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';

export default function MakersAwardContent({ images, content }) {
  // 面板数量
  const panelCount = images.length || content.length || 0;
  const [activeImage, setActiveImage] = useState(images[0]);

  const onActiveIndexChange = (index) => {
    setActiveImage(images[index]);
  };

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (<div className={`${isEn ? 'en' : 'zh'} flex items-center justify-center h-[720px]`}>
            <Accordion direction="vertical" panelCount={panelCount} content={content} wrapperClsNames="h-full w-[50%] bg-[#353537]" onActiveIndexChange={onActiveIndexChange}>
              <Content images={images} content={content} />
            </Accordion>
            <div className={'hoverPhoto w-[50%] h-full relative'}>
              {
                images.map((img, index) => (
                  <img key={index} className={`w-full h-full left-top absolute transition-opacity duration-1000 ${activeImage === img ? 'opacity-1' : 'opacity-0'}`} src={img} alt="img" />
                ))
              }
            </div>
          </div>);
        }
      }
    </IntlContextConsumer>
  );
}

function Content({
  activeIndex, currentIndex, content,
}) {
  const active = activeIndex === currentIndex;

  return (
    <div key={currentIndex} className={`absolute top-0 left-0 w-full h-full ${active ? 'bg-none items-start flex-col' : 'bg-[#1D1D1F] items-center'} overflow-hidden flex justify-between px-4 py-6 lg:px-8 lg:py-10`}>
      <div dangerouslySetInnerHTML={{ __html: content[currentIndex].title }} />
      <p className={`w-full max-h-[300px] ${active ? 'block' : 'hidden'} overflow-y-auto custom-scrollbar hover:w-[calc(100%+4px)]`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
      { activeIndex !== currentIndex && <span className="iconfont iconarrow_down !text-xl font-bold"></span>}
    </div>
  );
}
