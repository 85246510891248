/**
 * 主页内容创造者价值观 - Makers创造者的价值观
 */
import React from 'react';
import SectionTitle from './v2/section-title';
import MakersValuesContentMobile from './makers-values-content-mobile';
import useDeviceType from '../../hooks/useDeviceType';
import ScrollDrivenAnimation from './scroll-driven-animation';
import MakersValuesContent from './makers-values-content';
import './makers-values.scss';

export default function MakersValues({
  title, subTitle, image, content,
}) {
  const { isMobile } = useDeviceType();
  const images = isMobile ? image.mobile : image.pc;

  return (
  // <ScrollDrivenAnimation>
      <div className="makersValues max-w-[1200px] m-auto px-6 xl:px-0 pb-20 xl:pb-46">
        <SectionTitle title={title} subTitle={subTitle} />
        {isMobile ? <MakersValuesContentMobile images={images} content={content} />
          : <MakersValuesContent images={images} content={content} />}
      </div>
  // </ScrollDrivenAnimation>
  );
}
