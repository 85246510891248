/**
 * 模块标题 v2（20250307） 迭代
 */
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { motion } from 'motion/react';
import useLanguage from '../../../hooks/useLanguage';

/**
 * 模块标题
 * @param {*}  title 标题
 * @param {*}  subTitle 副标题
 * @param {*}  description 描述
 * @param {*}  className 样式
 * @param {*}  colors 颜色
 * @param {*}  motion 是否开启动画
 * @returns
 */
const SectionTitle = ({
  title, subTitle, description, className, colors: {
    titleColor, subTitleColor, descriptionColor,
  } = {},
  enableMotion = true,
  motionProps = {
    initial: { opacity: 0, y: 100 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.5, ease: 'easeIn' },
  },
}) => {
  const { isEn } = useLanguage();
  const ContainerEle = enableMotion ? motion.div : 'div';

  // title 字体
  const titleFontCls = isEn ? 'text-[40px] leading-[46px] md:text-[64px] md:leading-[68px]' : 'text-[40px] leading-[46px] md:text-[48px] md:leading-[64px]';
  // title 宽度
  const titleWCls = isEn ? 'w-[632px]' : 'w-[575px]';
  // title 样式
  const totalClassName = twMerge('flex flex-wrap items-start justify-between max-w-[1200px] m-auto pt-20 lg:pt-40 pb-8 md:pb-20', className);
  const titleClsNames = `mb-4 md:mb-0 ${titleWCls} ${isEn ? 'font-title-en' : 'font-title-zh'} ${titleFontCls} ${titleColor ? `text-${titleColor}` : 'text-white'}`;
  // subTitle 字体
  const subTitleFontCls = isEn ? 'text-base' : 'text-base md:text-xl';
  // subTitle 样式
  const subTitleClsNames = `font-content ${subTitleFontCls} ${subTitleColor ? `text-${subTitleColor}` : 'text-[#86868C]'}`;

  // subTitle 字体
  const descriptionFontCls = isEn ? 'text-xs' : 'text-sm';
  // description 样式
  const descriptionClsNames = `mt-4 font-thin ${descriptionFontCls} ${descriptionColor ? `text-${descriptionColor}` : 'text-[#86868C]'}`;
  return (
    <ContainerEle
      className={`section-title ${totalClassName}`}
      {
        ...(enableMotion ? motionProps : {})
      }
      >
      <h1 className={ titleClsNames } dangerouslySetInnerHTML={{ __html: title }} />
      <div className={`w-full ${isEn ? 'md:w-[417px]' : 'md:w-[524px]'} flex flex-col`}>
        {subTitle && (
          <h5
            className={subTitleClsNames}
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        )}
        {description && (
          <p
            className={descriptionClsNames}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </ContainerEle>
  );
};

export default SectionTitle;
