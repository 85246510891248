/**
 * makers-paradise 主体内容 - 移动端
 */
import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { range } from 'lodash';
import SectionTitle from './v2/section-title';

export default function MakersParadiseContentMobile({
  title, subTitle, images, content,
}) {
  return (
    <>
      <div className="makersParadise max-w-[1200px] m-auto px-6 xl:px-0 bg-light-white-linear">
        <SectionTitle title={title} subTitle={subTitle} colors={{ titleColor: 'black' }} />
        <Content images={images} content={content} />
      </div>
    </>
  );
}

function Content({
  images, content,
}) {
  const count = images.length || content.length || 0;

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (
            <>
              {
                range(count).map((_, index) => (
                  <div key={index} className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
                    <img className="w-full object-cover" src={images[index]} alt="img" />
                    <div className={'w-full items-start flex-col flex justify-end py-6 pb-14'}>
                      <div className="w-full" dangerouslySetInnerHTML={{ __html: content[index].title }} />
                      <div dangerouslySetInnerHTML={{ __html: content[index].info }} />
                    </div>
                  </div>
                ))
              }
            </>);
        }
      }
    </IntlContextConsumer>
  );
}
