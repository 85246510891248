import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import AnkerUserIntro from '../components/home/anker-user-intro';
import * as styles from './index.module.scss';
import '../styles/index.css';
import AnkerIntroduce from '../components/home/anker-introduce';
import MakersValues from '../components/home/makers-values';
import InnovativeProducts from '../components/home/innovative-products';
import MakersAward from '../components/home/makers-award';
import AnkerStory from '../components/home/anker-story';
import AnkerRecruitingprocess from '../components/home/anker-recruitingprocess';
import AnkerJoinus from '../components/home/anker-joinus';
import ImageBanner from '../components/ImageBanner';
import ParadiseVideoWrap from '../components/home/paradise-video-wrap';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pageIndex: 1,
      pageSize: 20,
      offsetWidth: 0,
    };
  }

  componentDidMount() {
    const { offsetWidth } = document.getElementById('___gatsby');
    this.setState({ offsetWidth });
    var _hmt = _hmt || [];
    (function () {
      const hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?d059262ab27f44a393e7f89bebb1a438';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    }());
  }

  render() {
    const { offsetWidth } = this.state;
    const { data, location } = this.props;
    const copywriting = data.contentfulCopywriting;
    return (
      <div className={styles.home}>
        <ImageBanner content={data.contentfulCopywriting.home.newbanner} color={'#1D1D1F'} isHome enableCover />

        <Layout {...this.props} headerProps={{ isHome: true }} copywriting={copywriting}>
          {copywriting.home.seo && <SEO
            title={copywriting.home.seo.title}
            keywords={copywriting.home.seo.keywords}
            description={copywriting.home.seo.description} />}
          <div className="bg-light-bule-linear relative">
            {copywriting.home.introduce && (
              <AnkerIntroduce {...copywriting.home.introduce} location={location} />
            )}
            {copywriting.home.innovativeProducts
              && (
                <InnovativeProducts
                  {...copywriting.home.innovativeProducts}
                  location={location}
                />
              )}
          </div>
          { (copywriting.home.makersParadise || copywriting.home.video)
            && (
              <ParadiseVideoWrap
                makersParadise={copywriting.home.makersParadise}
                video={copywriting.home.video}
                location={location}
              />
            )
          }
          <div className="bg-black">
            {copywriting.home.story && (
              <AnkerStory {...copywriting.home.story} location={location} />
            )}
            {copywriting.home.makersAward
              && <MakersAward {...copywriting.home.makersAward} location={location} />}
            {copywriting.home.makersValues
              && <MakersValues {...copywriting.home.makersValues} location={location} />}
            {copywriting.home.RecruitingProcess && (
              <AnkerRecruitingprocess
                RecruitingProcess={copywriting.home.RecruitingProcess}
                faq={copywriting.faq}
                location={location}
              />
            )}
            {copywriting.home.JoinUs && (
              <AnkerJoinus {...copywriting.home.JoinUs} location={location} />
            )}
          </div>
        </Layout>
      </div>
    );
  }
}

export default injectIntl(Home);

// homejob {
//   image
//   content
//   more {
//     text
//     url
//   }
//   title
// }

export const query = graphql`
query Copywriting($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    home {
      newbanner {
        pc
        title
        mobiletitle
        mobile
        buttomList {
          link
          text
        }
        pcPoster
        mobilePoster
      }
      introduce {
        title
        desc
        mobiledesc
      }
      story {
        title
        list {
          image
          mobileimage
          title
          subtitle
          desc
        }
      }
      find {
        title
        list {
          image
          mobileimage
          title
          desc
        }
      }
      RecruitingProcess {
        title
        list {
          title
          desc
        }
        buttomList {
          text
        }
      }
      JoinUs {
        title
        subtitle
        pcbg
        mobilebg
        buttomList {
          link
          text
        }
      }
      banner {
        img
        jump_link
      }
      intro {
        content
        image
        title
        more {
          text
          url
        }
      }
      section1 {
        content
        image
        title
      }
      innovativeProducts {
        content {
          title
          info
        }
        image {
          pc {
            expand
            collapse
          }
          mobile {
            expand
            collapse
          }
        }
        title
        subTitle
        comment
      }
      makersParadise {
        content {
          title
          info
        }
        image {
          pc {
            expand
            collapse
          }
          mobile
        }
        title
        subTitle
      }
      makersAward {
        content {
          title
          info
        }
        image {
          pc
          mobile
        }
        title
        subTitle
      }
      makersValues {
        content {
          title
          subTitle
          info
        }
        image {
          pc {
            expand
            collapse
          }
          mobile {
            expand
            collapse
          }
        }
        title
        subTitle
      }
      seo {
        description
        keywords
        title
      }
      video {
        subText
        text
        url {
          pc
          mobile
        }
        jump_link
        poster {
          pc
          mobile
        }
      }
    }
    faq {
      type
      title
      list {
        question
        answer
      }
    }
  }
}
`;
