/**
 * makers-paradise 主体内容 pc 端
 */
import React, { useEffect, useRef, useState } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { throttle } from 'lodash';
import SectionTitle from './v2/section-title';

export default function MakersParadiseContent({
  title, subTitle, images, content, onOpacityStartChange,
}) {
  // 页面内容 ref
  const contentRef = useRef(null);
  // 页面透明度，默认完全不透明
  const [opacity, setOpacity] = useState(0);

  const handleOpacityChange = (curOpacity) => {
    setOpacity(curOpacity);
  };

  return (
    <div className={'makersParadise relative z-[1]'} style={{ opacity }}>
      <span className={'block absolute left-0 top-0 w-full bg-light-white-linear h-[calc(100%+200vh)] z-0'}></span>
      <div ref={contentRef} className="relative max-w-[1200px] m-auto px-6 xl:px-0">
        <SectionTitle title={title} subTitle={subTitle} colors={{ titleColor: 'black' }} />
        <Content
          images={images}
          content={content}
          onOpacityChange={handleOpacityChange}
          onOpacityStartChange={onOpacityStartChange}
        />
      </div>
    </div>
  );
}

/**
 *
 * @param {*} onOpacityChange 整体的 opactiy 变化实际，依据图片滚动位置事实变化，通过该回调告知父组件
 * @returns
 */
function Content({
  images, content, style, onOpacityChange, onOpacityStartChange,
}) {
  // 当前面板是否展开
  const [activeIndex, setActiveIndex] = useState(0);
  const allImages = images.expand || [];
  const textRef = useRef(null);
  const containerRef = useRef(null);

  /**
   * 获取 textContainer 元素内，高度最大的子元素的高度
   * @param {*} textContainer
   * @returns
   */
  function getMaxHeightTextChild(textContainer) {
    if (!textContainer || !textContainer.children || textContainer.children.length === 0) {
      return 0;
    }

    const maxHeight = Array.from(textContainer.children).reduce((maxH, child) => {
      const { height } = child.getBoundingClientRect();
      return height > maxH ? height : maxH;
    }, 0);

    return maxHeight;
  }

  useEffect(() => {
    if (!textRef.current) return;
    let io;

    const roCallback = ([text] = []) => {
      if (!textRef.current) return;
      const rootMarginBottom = window.innerHeight * 0.2;
      // 文本高度
      const textHeight = getMaxHeightTextChild(text?.target || textRef.current);
      const rootMarginTop = window.innerHeight - textHeight - rootMarginBottom - 20;
      const rootMargin = `-${rootMarginTop < 0 ? 0 : rootMarginTop}px 0px -${rootMarginBottom}px  0px`;
      if (io) io.disconnect();
      io = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) return;
        const index = parseInt(entry.target.id, 10);
        setActiveIndex(parseInt(index, 10));
      }, {
        root: null, // 显式声明根元素为视口
        rootMargin,
        threshold: 1,
      });

      [...textRef.current.children].forEach((child) => {
        io.observe(child);
      });
    };

    // 监听文本容器元素大小变化
    const ro = new ResizeObserver(roCallback);
    ro.observe(textRef.current);

    roCallback();

    return () => {
      io.disconnect();
      ro.disconnect();
    };
  }, [textRef]);

  // 图片容器的 ref
  const imgContainerRef = useRef(null);
  // 动态计算图片固定时的 top 值
  useEffect(() => {
    if (!imgContainerRef.current) return;
    // 监听图片的 IntersectionObserver 实例
    let io;
    // 是否已经开始透明度变化，若开始，则调用 onOpacityStartChange，并将该变量置为 true
    let startOpacityChange = false;

    const scrollHandler = (target) => {
      // eslint-disable-next-line no-param-reassign
      target = target || imgContainerRef.current.children[0];
      const { height, bottom } = target.getBoundingClientRect();
      // 滚动到图片底部 3/1 时，透明度变化
      const changeOpacityHeight = height / 3;
      let finalOpacity = 1;
      // 若 bottom <= 0，遮罩完全透明
      if (bottom <= 0) finalOpacity = 0;
      // 若 bottom >= changeOpacityHeight
      else if (bottom >= changeOpacityHeight) {
        finalOpacity = 1;
      } else {
      // 当 0 < bottom < changeOpacityHeight 时，按比例设置透明度，越向下滚动，透明度越高
        if (!startOpacityChange) {
          startOpacityChange = true;
          if (onOpacityStartChange) {
            onOpacityStartChange(containerRef.current.getBoundingClientRect().bottom);
          }
        }
        finalOpacity = bottom / changeOpacityHeight;
      }
      if (onOpacityChange) onOpacityChange(finalOpacity);
    };

    const ro = new ResizeObserver(([entry]) => {
      if (!imgContainerRef.current) return;
      const windowHeight = window.innerHeight;
      const imgHeight = entry.contentRect.height;
      // 图片 sticky 出发的 top 值
      let topPx = 0;
      if (imgHeight < windowHeight) {
        topPx = (windowHeight - imgHeight) / 2;
      }
      imgContainerRef.current.style.top = `${topPx}px`;

      if (io) io.disconnect();
      // 监听图片滚动位置，实时更新透明度
      io = new IntersectionObserver(([imgEntry]) => {
        const handleScroll = throttle(() => scrollHandler(imgEntry.target), 100);
        if (entry.intersectionRatio <= 0) {
          window.removeEventListener('scroll', handleScroll);
        } else {
          window.addEventListener('scroll', handleScroll);
        }
      }, {
        root: null, // 显式声明根元素为视口
        threshold: 0,
      });

      io.observe(entry.target);
    });

    if (imgContainerRef.current.children.length > 0) {
      ro.observe(imgContainerRef.current.children[0]);
    }
    // 初始化 opactiy 状态
    scrollHandler();

    return () => {
      ro.disconnect();
      if (io) io.disconnect();
    };
  }, [imgContainerRef, containerRef]);

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (
            <div ref={containerRef} className={`w-full flex justify-between ${isEn ? 'en' : 'zh'} `} style={style}>
              <div ref={imgContainerRef} className={'sticky w-1/2 max-w-[34rem] flex flex-col items-center  h-screen'}>
                {
                  allImages.map((img, index) => (
                    <img key={index} className={`absolute w-full object-cover transition-opacity duration-500 ${activeIndex === index ? 'opacity: 100' : 'opacity-0'}`} src={img} alt="img" />
                  ))
                }
              </div>
              <div ref={textRef} className={`relative ${isEn ? 'w-[540px]' : 'w-[525px]'} py-[40vh] flex flex-col items-center`}>
                {
                  content.map((item, index) => (
                    <div key={index} id={index} className={`w-full h-full py-14 ${activeIndex === index ? 'opacity-100' : 'opacity-30'}`}>
                      <div dangerouslySetInnerHTML={{ __html: item.title }} />
                      <div dangerouslySetInnerHTML={{ __html: item.info }} />
                    </div>
                  ))
                }
              </div>
            </div>
          );
        }
      }
    </IntlContextConsumer>
  );
}
